import {
  Box,
  Heading,
  Image,
  Text,
  useMediaQuery,
  Flex,
} from "@chakra-ui/react";
import FeedbackImage from "../images/feedback_crop.png";
import { timeFormatter } from "../common/timeFormatter";

const FeedbackDetails = ({ training }) => {
  const [isMobile] = useMediaQuery("(max-width: 768px)");

  return (
    <Flex h="80%">
      {training && (
        <>
          <Flex
            w={{ base: "90%", md: "lg" }}
            bg="dxc.purple.500"
            borderTopRightRadius="3xl"
            p="8"
            m="auto"
            mt="8"
            mb="8"
            position="relative"
            direction="column"
          >
            <Text color="white" fontSize="2xl">
              Spätná väzba pre tréning
            </Text>
            <Heading
              as="h3"
              size="lg"
              mt="4"
              color="white"
              fontWeight="semibold"
              ml="auto"
              mr="auto"
            >
              {training?.name}
            </Heading>
            {isMobile ? (
              <>
                <Text color="white" mt="4">
                  od {timeFormatter(training?.startDateTime)}
                </Text>
                <Text color="white">
                  do {timeFormatter(training?.endDateTime)}
                </Text>
              </>
            ) : (
              <Text color="white" mt="4">
                {timeFormatter(training?.startDateTime)} -{" "}
                {timeFormatter(training?.endDateTime)}
              </Text>
            )}

            <Text color="white" mt="2">
              Tréner: {training?.organizer}
            </Text>
          </Flex>
          {!isMobile && training.image && (
            <Image
              src={training.image}
              alt=""
              maxH="60"
              m="auto"
              ml="8"
              // gradient on all sides
              sx={{
                WebkitMaskImage: "linear-gradient(to top, transparent 10%, #fff 15% 90%, transparent 95%), linear-gradient(to left, transparent 10%, #fff 15% 90%, transparent 95%)",
                WebkitMaskSize: "110% 110%",
                WebkitMaskPosition: "center",
                WebkitMaskRepeat: "no-repeat",
                WebkitMaskComposite: "source-in",
                maskImage: "linear-gradient(to top, transparent 10%, #fff 15% 90%, transparent 95%), linear-gradient(to left, transparent 10%, #fff 15% 90%, transparent 95%)",
                maskSize: "110% 110%",
                maskPosition: "center",
                maskRepeat: "no-repeat",
                maskComposite: "intersect"
                }}
            />
          )}
        </>
      )}
    </Flex>
  );
};

export default FeedbackDetails;
