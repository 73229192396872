import moment from "moment/moment";

export const timeFormatter = (stringDate) => {
  const date = moment(stringDate);
  return date.format("DD.MM.YYYY HH:mm");
};

export const editorTimeFormatter = (stringDate) => {
  const date = moment(stringDate);
  return date.format("YYYY-MM-DDTHH:mm");
};
