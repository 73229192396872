import {
  Box,
  Text,
  Heading,
  Button,
  Stack,
  Container,
  Link,
} from "@chakra-ui/react";

import { ArrowForwardIcon } from "@chakra-ui/icons";

const NotFound = () => {
  return (
    // center box on page
    <>
      <Container maxW={"3xl"}>
        <Stack
          as={Box}
          textAlign={"center"}
          spacing={{ base: 8, md: 14 }}
          py={{ base: 20, md: 36 }}
        >
          <Heading
            fontWeight={600}
            fontSize={{ base: "3xl", sm: "4xl", md: "6xl" }}
            lineHeight={"110%"}
          >
            404 <br />
            <Text as={"span"} color={"primary.500"} fontSize={{ base: "2xl", sm: "3xl", md: "5xl" }}> 
              Stránka nenájdená
            </Text>
          </Heading>
          <Text color={"gray.500"}>
            Stránka ktorú hľadáte neexistuje.
            <br />
            Pokiaľ sa chcete dostať na stránku s tréningmi, kliknite na tlačidlo
            nižšie.
          </Text>
          <Link href={process.env.REACT_APP_TRAINING_APP_URL}>
            <Button
              variant={"link"}
              colorScheme={"primary"}
              size={"sm"}
              rightIcon={<ArrowForwardIcon />}
            >
              Prejsť na tréningy DXC
            </Button>
          </Link>
        </Stack>
      </Container>
    </>
  );
};

export default NotFound;
