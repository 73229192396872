import {
  Stack,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  Textarea,
  InputGroup,
  Input,
  FormErrorMessage,
  Button,
  Box,
  InputRightElement,
} from "@chakra-ui/react";
import { AiOutlineNumber } from "react-icons/ai";
import { PhoneIcon, EmailIcon } from "@chakra-ui/icons";
import { Formik, Field } from "formik";
import * as yup from "yup";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";

const initialValues = {};

const FeedbackQuestions = ({ questions }) => {
  const params = useParams();
  const feedbackId = params.feedbackId;
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);

  let validationSchema = yup.object();
  questions.forEach((question) => {
    let qid = question.id;
    initialValues[question.id] = "";
    if (question.type === "TextPrompt") {
      if (question.validator === "Email") {
        if (question.required) {
          validationSchema = validationSchema.shape({
            [qid]: yup.string().email().required("Povinné pole"),
          });
        } else {
          validationSchema = validationSchema.shape({
            [qid]: yup.string().email(),
          });
        }
      } else if (question.validator === "Phone") {
        if (question.required) {
          validationSchema = validationSchema.shape({
            [qid]: yup
              .string()
              .matches(/^[0-9]+$/, "Zadejte platné telefónne číslo")
              .required("Povinné pole"),
          });
        } else {
          validationSchema = validationSchema.shape({
            [qid]: yup
              .string()
              .matches(/^[0-9]+$/, "Zadejte platné telefónne číslo"),
          });
        }
      } else if (question.validator === "Number") {
        if (question.required) {
          validationSchema = validationSchema.shape({
            [qid]: yup
              .number()
              .required("Povinné pole")
              .typeError("Zadajte číslo"),
          });
        } else {
          validationSchema = validationSchema.shape({
            [qid]: yup.number().typeError("Zadajte číslo"),
          });
        }
      } else {
        if (question.required) {
          validationSchema = validationSchema.shape({
            [qid]: yup.string().required("Povinné pole"),
          });
        } else {
          validationSchema = validationSchema.shape({ [qid]: yup.string() });
        }
      }
    } else if (question.type === "ChoicePrompt") {
      if (question.required) {
        validationSchema = validationSchema.shape({
          [qid]: yup.string().required("Povinné pole"),
        });
      } else {
        validationSchema = validationSchema.shape({ [qid]: yup.string() });
      }
    }
  });

  const onSubmit = (values) => {
    setIsSubmitting(true);
    axios
      .post(
        `${process.env.REACT_APP_BACKENDURL}/api/feedback/${feedbackId}`,
        values
      )
      .then((response) => {
        setIsSubmitting(false);
        navigate(`/feedback/${feedbackId}/redirect`, {
          state: {
            fromApp: true,
            redirectionType: "success",
            redirectionCode: "FEEDBACK_SUCCESS",
          },
        });
      })
      .catch((error) => {
        setIsSubmitting(false);
        // TODO could be done without if but no time to test if every error has correct response
        if (error.response.data.errorCode === "FEEDBACK_NOT_FOUND") {
          navigate(`/feedback/${feedbackId}/redirect`, {
            state: {
              fromApp: true,
              redirectionType: "error",
              redirectionCode: "FEEDBACK_NOT_FOUND",
            },
          });
        } else if (
          error.response.data.errorCode === "FEEDBACK_DEADLINE_EXPIRED"
        ) {
          navigate(`/feedback/${feedbackId}/redirect`, {
            state: {
              fromApp: true,
              redirectionType: "error",
              redirectionCode: "FEEDBACK_DEADLINE_EXPIRED",
            },
          });
        } else {
          navigate(`/feedback/${feedbackId}/redirect`, {
            state: {
              fromApp: true,
              redirectionType: "error",
              redirectionCode: "FEEDBACK_ERROR",
            },
          });
        }
      });
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      validateOnChange={false}
    >
      {({ handleSubmit, errors, touched, values, setFieldValue }) => (
        <form onSubmit={handleSubmit}>
          <Stack spacing={4}>
            {questions?.map((question) => (
              <Box key={question.id} pl={question.isSubquestion && "4"}>
                {question.type === "TextPrompt" ? (
                  <FormControl
                    isInvalid={!!errors[question.id] && touched[question.id]}
                  >
                    <FormLabel>
                      {!question.isSubquestion
                        ? question.questionNumber + ". " + question.text
                        : question.text}{" "}
                      {question.required ? (
                        <span style={{ color: "#E53E3E" }}>*</span>
                      ) : null}
                    </FormLabel>
                    {!question.validator ? (
                      <Field
                        name={question.id}
                        placeholder=" "
                        as={Textarea}
                        size="sm"
                        rounded="md"
                        focusBorderColor="dxc.purple.500"
                      />
                    ) : question.validator === "Phone" ? (
                      <InputGroup size="sm">
                        <InputRightElement
                          pointerEvents="none"
                          children={<PhoneIcon color="gray.300" />}
                        />
                        <Field
                          name={question.id}
                          placeholder=" "
                          as={Input}
                          size="sm"
                          rounded="md"
                          focusBorderColor="dxc.purple.500"
                        />
                      </InputGroup>
                    ) : question.validator === "Number" ? (
                      <InputGroup size="sm">
                        <InputRightElement
                          pointerEvents="none"
                          children={<AiOutlineNumber color="#A0AEC0" />}
                        />
                        <Field
                          name={question.id}
                          placeholder=" "
                          as={Input}
                          size="sm"
                          rounded="md"
                          focusBorderColor="dxc.purple.500"
                        ></Field>
                      </InputGroup>
                    ) : question.validator === "Email" ? (
                      <InputGroup size="sm">
                        <InputRightElement
                          pointerEvents="none"
                          children={<EmailIcon color="gray.300" />}
                        />
                        <Field
                          name={question.id}
                          placeholder=" "
                          as={Input}
                          size="sm"
                          rounded="md"
                          focusBorderColor="dxc.purple.500"
                        />
                      </InputGroup>
                    ) : (
                      <Field
                        name={question.id}
                        placeholder=" "
                        as={Textarea}
                        size="sm"
                        rounded="md"
                        focusBorderColor="dxc.purple.500"
                      />
                    )}
                    <FormErrorMessage>{errors[question.id]}</FormErrorMessage>
                  </FormControl>
                ) : question.type === "ChoicePrompt" ? (
                  <FormControl
                    isInvalid={!!errors[question.id] && touched[question.id]}
                  >
                    <FormLabel>
                      {!question.isSubquestion
                        ? question.questionNumber + ". " + question.text
                        : question.text}{" "}
                      {question.required ? (
                        <span style={{ color: "#E53E3E" }}>*</span>
                      ) : null}
                    </FormLabel>
                    <RadioGroup
                      name={question.id}
                      onChange={(e) => setFieldValue([question.id], e)}
                    >
                      <Stack direction="column">
                        {question.choices?.map((choice, id) => (
                          <Radio
                            value={choice}
                            key={id}
                            _checked={{
                              borderColor: "dxc.purple.500",
                              borderWidth: "5px",
                            }}
                          >
                            {choice}
                          </Radio>
                        ))}
                      </Stack>
                    </RadioGroup>
                    <FormErrorMessage>{errors[question.id]}</FormErrorMessage>
                  </FormControl>
                ) : (
                  <FormLabel>
                    {!question.isSubquestion
                      ? question.questionNumber + ". " + question.text
                      : question.text}
                  </FormLabel>
                )}
              </Box>
            ))}
            <Button
              type="submit"
              colorScheme="primary"
              isLoading={isSubmitting}
            >
              Odoslať
            </Button>
          </Stack>
        </form>
      )}
    </Formik>
  );
};

FeedbackQuestions.defaultProps = {
  questions: [],
};

export default FeedbackQuestions;
